<script>
import {
  ORDER_SUCCESS_TITLE,
  ORDER_FAIL_TITLE,
  ORDER_SUCCESS_MESSAGE,
  ORDER_FAIL_CREATION_MESSAGE,
  GENERAL_ORDER_FAIL_MESSAGE,
} from "../constants";

export default {
  name: "Payment",
  data() {
    return {
      event: {},
      loading: true,
    };
  },
  methods: {
},
  computed: {
    paymentSuccess() {
      return this.$route.query.success.toLowerCase() === "true";
    },
    paymentMessage() {
      return this.$route.query.message;
    },
    orderResultTitle() {
      return this.paymentSuccess ? ORDER_SUCCESS_TITLE : ORDER_FAIL_TITLE;
    },
    orderInformation() {
        let errorMessage = this.paymentSuccess ? ORDER_SUCCESS_MESSAGE : GENERAL_ORDER_FAIL_MESSAGE;
        if (this.paymentMessage == "Failed to create order") {
            errorMessage = ORDER_FAIL_CREATION_MESSAGE;
        }

        return errorMessage;
    },
  },
  mounted() {
  },
};
</script>

<template>
  <div>
    <div class="pink-border slim-page payment-container align-items-center" >
      <h2>{{ orderResultTitle }}</h2>
      <p>{{ paymentMessage }}</p>
      <p>{{ orderInformation }}</p>
      <router-link to="/orders" tag="button" v-if="paymentSuccess">Orders</router-link>
    </div>
  </div>
</template>
