<script>
export default {
  name: "ValidIndicator",
  props: {
    valid: Boolean,
  },
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="inline-block h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      v-if="valid"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5 13l4 4L19 7"
    ></path>
    <path
      v-else
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M6 18L18 6M6 6l12 12"
    ></path>
  </svg>
</template>
