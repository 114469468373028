<script>
import axios from "axios";
import order from "./Order.vue";
import { BASE_API_URL } from "../constants";
import { ensureLoggedInOrRedirect } from "../utils";

export default {
  name: "Orders",
  components: {
    order,
  },
  data() {
    return {
      orders: [],
      busy: true,
    };
  },
  methods: {
    getOrders() {
      this.error = false;
      axios
        .get(BASE_API_URL + "/Orders", { withCredentials: true })
        .then(({ data }) => {
          this.orders = data.orders;
          this.busy = false;
        })
        .catch(() => {
          this.$router.push({ path: "/" });
        });
    },
  },
  mounted() {
    if (ensureLoggedInOrRedirect(this.$router)) return;
    this.getOrders();
  },
};
</script>

<template>
  <div>
    <div class="loader-parent-overlay" v-if="busy">
      <div class="p-75 loading-spinner"></div>
    </div>
    <div class="flex-grid">
      <order v-for="order in orders" :key="order.orderNumber" :order="order" />
    </div>
  </div>
</template>
