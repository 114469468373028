<script>
import axios from "axios";
import Ticket from "./Ticket.vue";
import {
  TICKETS_LIMIT,
  TICKETS_FULLY_BOOKED_MESSAGE,
  HOSPITALITY_EMAIL,
  HOSPITALITY_DISPLAY_EMAIL,
  BASE_API_URL,
  IMAGE_BASE_URL,
  HPP_URL,
  CALLBACK_URL,
  PAYMENT_PAGE_REDIRECT_ERROR_MESSAGE,
} from "../constants";
import { formatCurrency, formatDate, ensureLoggedInOrRedirect } from "../utils";
import { RealexHpp } from "../rxp-js";

export default {
  name: "Tickets",
  components: {
    Ticket,
  },
  data() {
    return {
      event: {},
      ticketsToBook: 0,
      busy: true,
      disabledAccessAnswer: "",
      total: 0,
      error: false
    };
  },
  methods: {
    getTickets() {
      axios
        .get(BASE_API_URL + "/Tickets/" + this.$route.params.performanceId, {
          withCredentials: true,
        })
        .then(({ data }) => {
          this.event = data;
          this.busy = false;
        })
        .catch(() => {
          this.$router.push({ path: "/" });
        });
    },
    buyTickets() {
      this.busy = true;
      this.error = false;
      axios
        .post(
          BASE_API_URL + "/BuyTickets",
          {
            performanceId: this.$route.params.performanceId,
            accessibility: this.disabledAccessAnswer,
            tickets: this.event.tickets,
          },
          { withCredentials: true }
        )
        .then(({ data }) => {
            const dataObject = JSON.parse(data);
            RealexHpp.setHppUrl(HPP_URL);
            RealexHpp.redirect.init("redirectToPayment", CALLBACK_URL, dataObject);
            document.getElementById("redirectToPayment").click();
        })
        .catch(() => {
            this.error = true;
            this.busy = false;
        });
    },
    setQuantity(data) {
      const tickets = this.event.tickets;
      this.total = 0;
      this.ticketsToBook = 0;
      for (var i = 0; i < tickets.length; i++) {
        const ticket = tickets[i];
        if (ticket.priceTypeId == data.priceTypeId) {
          ticket.quantity = data.quantity;
        }
        this.total += ticket.price * ticket.quantity;
        this.ticketsToBook += ticket.quantity;
      }
    },
  },
  computed: {
    ticketsLimit() {
      return TICKETS_LIMIT;
    },
    remainingTickets() {
      const totalUsedTickets =
        this.event.purchasedTicketQty + this.ticketsToBook;
      return this.ticketsLimit - totalUsedTickets;
    },
    formattedDate() {
      const date = new Date(this.event.startDate);
      return formatDate(date);
    },
    isTicketsLimitExceeded() {
      return this.remainingTickets < 0;
    },
    ticketsFullyBooked() {
      return this.event.purchasedTicketQty >= TICKETS_LIMIT;
    },
    ticketsFullyBookedMessage() {
      return TICKETS_FULLY_BOOKED_MESSAGE;
    },
    hospitalityEmail() {
      return HOSPITALITY_EMAIL;
    },
    hospitalityDisplayEmail() {
      return HOSPITALITY_DISPLAY_EMAIL;
    },
    isTicketQuantityZero() {
      return this.event.tickets.every((t) => t.quantity == 0);
    },
    preventBuyTickets() {
      return this.isTicketQuantityZero || this.isTicketsLimitExceeded;
    },
    logoPath() {
      if (this.event.logoPath) {
        return IMAGE_BASE_URL + this.event.logoPath;
      } else {
        return "ArenaClubLogo.png";
      }
    },
    formattedTotal() {
      return formatCurrency(this.total);
    },
    errorMessage() {
        return PAYMENT_PAGE_REDIRECT_ERROR_MESSAGE;
    }
  },
  mounted() {
    if (ensureLoggedInOrRedirect(this.$router)) return;
    this.getTickets();
  },
};
</script>

<template>
  <div>
    <div class="loader-parent-overlay" v-if="busy">
      <div class="p-75 loading-spinner"></div>
    </div>
    <div class="pink-border slim-page" v-else-if="event">
      <div>
        <img class="event-image" :src="logoPath" alt="Event Image" />
      </div>
      <div class="flex-display p-75">
        <div>
          <h2>{{ event.name }}</h2>
          <div>{{ formattedDate }}</div>
          <div v-if="ticketsFullyBooked">
            {{ ticketsFullyBookedMessage }}
            <a :href="hospitalityEmail">{{ hospitalityDisplayEmail }}</a>
          </div>
          <template v-else>
            <template>
              <table>
                <tr>
                  <td colspan="3"></td>
                  <td class="t-a-r">Booked</td>
                  <td></td>
                </tr>
                <ticket
                  v-for="ticket in event.tickets"
                  :key="ticket.priceType"
                  :ticket="ticket"
                  :ticketsAvailable="remainingTickets"
                  @setQuantity="setQuantity"
                ></ticket>
                <tr>
                  <td colspan="3"></td>
                  <td class="t-a-r">Total:</td>
                  <td class="t-a-r">{{ formattedTotal }}</td>
                </tr>
              </table>
              <div>
                <button
                  :disabled="preventBuyTickets"
                  @click="buyTickets()"
                  class="m-t-15"
                >
                  Buy Tickets
                </button>
              </div>
              <div v-if="error" class="message-box error-message">
                <span>
                    {{ errorMessage }}
                </span>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <button id="redirectToPayment" hidden>
    </button>
  </div>
</template>
