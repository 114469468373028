<script>
import axios from "axios";
import Event from "./Event.vue";
import { BASE_API_URL, LOGGED_IN_KEY } from "../constants";
import { ensureLoggedInOrRedirect, deleteCookie } from "../utils";

export default {
  name: "Events",
  components: {
    Event,
  },
  data() {
    return {
      events: [],
      error: false,
      busy: true,
    };
  },
  methods: {
    getEvents() {
      this.error = false;
      axios
        .get(BASE_API_URL + "/Events", { withCredentials: true })
        .then(({ data }) => {
          this.events = data.map((e) => {
            return {
              index: e.key,
              ...e.value,
              available: e.available,
              boxEventStatus: e.boxEventStatus,
            };
          });
          this.busy = false;
        })
        .catch((error) => {
          if (error.response.status === 401)
            deleteCookie(LOGGED_IN_KEY);
          this.$router.push({ path: "/" });
        });
    },
  },
  computed: {},
  mounted() {
    if (ensureLoggedInOrRedirect(this.$router)) return;
    this.getEvents();
  },
};
</script>

<template>
  <div>
    <div class="loader-parent-overlay" v-if="busy">
      <div class="p-75 loading-spinner"></div>
    </div>
    <div class="flex-grid" v-else>
      <event
        v-for="event in events"
        :key="event.index"
        :event="event"
        :eventCode="event.index"
      ></event>
    </div>
  </div>
</template>
