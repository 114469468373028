<script>
import axios from "axios";
import {
  BOX_UNAVAILABLE_MESSAGE,
  HOSPITALITY_EMAIL,
  HOSPITALITY_DISPLAY_EMAIL,
  OFF_SALE_DATE_MESSAGE,
  IMAGE_BASE_URL,
  OPTING_OUT_CONFIRMATION_MESSAGE,
  BOX_OPTED_OUT_MESSAGE,
  BASE_API_URL,
  BOX_STATUS_OPTED_OUT,
  BOX_STATUS_OPTABLE,
} from "../constants";
import { formatDate } from "../utils";
export default {
  name: "Event",
  props: {
    event: Object,
    eventCode: String,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    formattedDate() {
      const date = new Date(this.event.startDate);
      return formatDate(date);
    },
    formattedCutOffDate() {
      if (this.event.offSaleDate == null) return "";
      const date = new Date(this.event.offSaleDate);
      return OFF_SALE_DATE_MESSAGE + formatDate(date);
    },
    boxUnavailableMessage() {
      return BOX_UNAVAILABLE_MESSAGE;
    },
    hospitalityEmail() {
      return HOSPITALITY_EMAIL;
    },
    hospitalityDisplayEmail() {
      return HOSPITALITY_DISPLAY_EMAIL;
    },
    optedOutMessage() {
      return BOX_OPTED_OUT_MESSAGE;
    },
    canBoxBeOptedOut() {
      return this.event.boxEventStatus == BOX_STATUS_OPTABLE;
    },
    hasBoxBeenOptedOut() {
      return this.event.boxEventStatus == BOX_STATUS_OPTED_OUT;
    },
    logoPath() {
      if (this.event.logoPath) {
        return IMAGE_BASE_URL + this.event.logoPath;
      } else {
        return "ArenaClubLogo.png";
      }
    },
  },
  methods: {
    tickets() {
      this.$router.push({
        path: "/tickets/" + this.eventCode,
      });
    },
    optOutMessageBox() {
      this.$confirm({
        message: OPTING_OUT_CONFIRMATION_MESSAGE,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.optOutOfBox();
          }
        },
      });
    },
    optOutOfBox() {
      axios
        .post(BASE_API_URL + "/OptOut/" + this.eventCode, null, {
          withCredentials: true,
        })
        .then(() => {
          this.$router.go();
        });
    },
  },
};
</script>

<template>
  <div class="pink-border">
    <div class="flex-display p-75 h-full">
      <div class="event-thumbnail">
        <img :src="logoPath" alt="Event Image" />
      </div>
      <div class="w-full p-relative p-b-40">
        <h2>{{ event.name }}</h2>
        <div>{{ formattedDate }}</div>
        <div>{{ formattedCutOffDate }}</div>
        <div class="h-min-7">
          <div v-if="hasBoxBeenOptedOut">
            <p>
              {{ optedOutMessage
              }}<a :href="hospitalityEmail">{{ hospitalityDisplayEmail }}</a>
            </p>
          </div>
          <div v-else-if="!event.available">
            <p>
              {{ boxUnavailableMessage }}
              <a :href="hospitalityEmail">{{ hospitalityDisplayEmail }}</a>
            </p>
          </div>
        </div>
        <div class="button-container p-absolute">
          <template v-if="event.available && !hasBoxBeenOptedOut">
            <div class="h-full">
              <button class="w-1/2" @click="tickets()">Event Tickets</button>
              <button
                v-if="canBoxBeOptedOut"
                class="w-1/2"
                @click="optOutMessageBox()"
              >
                Resell My Box
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
