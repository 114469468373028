<script>
// eslint-disable-next-line
import axios from "axios";
import SkinnyLayout from "./SkinnyLayout.vue";
import { BASE_API_URL } from "../constants";

export default {
  name: "ForgotPassword",
  components: {
    SkinnyLayout,
  },
  data() {
    return {
      busy: false,
      email: "",
      error: false,
      success: false,
    };
  },
  methods: {
    forgotPassword() {
      this.error = false;
      this.busy = true;
      axios
        .get(BASE_API_URL + "/ForgotPassword/" + this.email)
        .then(({ data }) => {
          if (data.success) {
            this.success = true;
          } else {
            this.error = true;
          }
          this.busy = false;
        })
        .catch(() => {
          this.error = true;
          this.busy = false;
        });
    },
  },
  computed: {
    preventSubmit() {
      return this.busy || this.email === "";
    },
  },
};
</script>

<template>
  <skinny-layout>
    <div v-if="error" class="message-box error-message">
      <span>Something went wrong. Please try again.</span>
    </div>

    <div v-if="success" class="message-box success-message">
      <span
        >If an account exists, a password reset link has been e-mailed.</span
      >
    </div>

    <form @submit.prevent="forgotPassword" v-if="!success">
      <div>
        <label for="email">Email Address</label>
        <input v-model="email" id="email" type="text" />
      </div>

      <div>
        <button :disabled="preventSubmit">Forgot Password</button>
      </div>
    </form>

    <footer>
      <router-link to="/" class="block m-t-15">Login</router-link>
    </footer>
  </skinny-layout>
</template>
