<script>
import axios from "axios";
import { BASE_API_URL } from "../constants";

export default {
  name: "TermsAndConditions",
  data() {
    return {
      termsAndConditions: "",
      loading: true,
    };
  },
  methods: {
    getTermsAndConditions() {
      this.error = false;
      axios
        .get(BASE_API_URL + "/TermsAndConditions")
        .then(({ data }) => {
          this.termsAndConditions = data.termsAndConditions;
          this.loading = false;
        })
        .catch(() => {
          this.$router.push({ path: "/" });
        });
    },
  },
  computed: {},
  mounted() {
    this.getTermsAndConditions();
  },
};
</script>

<template>
  <div>
    <div class="loader-parent-overlay" v-if="loading">
      <div class="p-75 loading-spinner"></div>
    </div>
    <div class="slim-page">
      <div class="pink-border p-75">
        <span v-html="this.termsAndConditions" />
      </div>
    </div>
  </div>
</template>
