<script>
import { formatCurrency } from "../utils";
import { TICKETS_LIMIT } from "../constants";

export default {
  name: "Ticket",
  props: {
    ticket: Object,
    ticketsAvailable: Number,
  },
  data() {
    return {
      loading: true,
      quantity: 0,
    };
  },
  computed: {
    ticketsLimit() {
      return TICKETS_LIMIT;
    },
    total() {
      return formatCurrency(this.quantity * this.ticket.price);
    },
    ticketPrice() {
      return formatCurrency(this.ticket.price);
    },
    availableQuantities() {
      const totalTickets = this.ticketsAvailable + this.quantity;

      return Array.from(Array(totalTickets + 1).keys());
    },
  },
  methods: {
    change() {
      this.$emit("setQuantity", {
        priceTypeId: this.ticket.priceTypeId,
        quantity: this.quantity,
      });
    },
  },
};
</script>

<template>
  <tr>
    <td>{{ ticket.priceType }}</td>
    <td class="t-a-r">{{ ticketPrice }}</td>
    <td class="t-a-r">
      <select v-model="quantity" @change="change">
        <option v-for="item in availableQuantities" :key="item" :value="item">
          {{ item }}
        </option>
      </select>
    </td>
    <td class="t-a-r">{{ ticket.bookedQuantity }}</td>
    <td class="t-a-r">{{ total }}</td>
  </tr>
</template>
