<template>
  <body id="app" class="p-5">
    <vue-confirm-dialog></vue-confirm-dialog>
    <nav v-if="isLoggedIn">
      <ul>
        <li>
          <router-link to="/events">Events</router-link>
        </li>
        <li>
          <router-link to="/orders">Orders</router-link>
        </li>
        <li>
          <router-link to="/changePassword">Change Password</router-link>
        </li>
        <li>
          <a @click="logout" href="#">Logout</a>
        </li>
      </ul>
    </nav>
    <router-view class="flex-10a content-padding"></router-view>
    <footer class="m-t-15 flex-no-shrink">
      <nav>
        <ul>
          <li>
            <router-link to="/faqs">FAQS</router-link>
          </li>
          <li>
            <router-link to="/termsAndConditions"
              >Terms and Conditions</router-link
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.mandsbankarena.com/information/privacy-notice/privacy-notice"
            >
              Privacy Notice
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  </body>
</template>

<script>
import axios from "axios";
import { BASE_API_URL, LOGGED_IN_KEY } from "./constants";
import { ensureLoggedInOrRedirect, deleteCookie, hasCookie } from "./utils";
export default {
  name: "App",
  data() {
    return {
      loggedIn: false,
    }
  },
  computed: {
    isLoggedIn() {
      return this.loggedIn;
    },
  },
  methods: {
    logout() {
      if (ensureLoggedInOrRedirect(this.$router)) return;
      axios
        .get(BASE_API_URL + "/Logout", { withCredentials: true })
        .then(() => {
          deleteCookie(LOGGED_IN_KEY);
          this.$router.push({ path: "/" });
        })
        .catch(() => {
          // TODO: Display an error message
          this.$router.push({ path: "/" });
        });
    },
  },
  mounted() {
    this.loggedIn = hasCookie(LOGGED_IN_KEY);
  },
  updated() {
    this.loggedIn = hasCookie(LOGGED_IN_KEY);
  }
};
</script>
