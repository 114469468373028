import Vue from "vue";
import Router from "vue-router";
import App from "./App.vue";
import VueConfirmDialog from 'vue-confirm-dialog';

import DownloadTickets from "./components/DownloadTickets.vue";
import Events from "./components/Events.vue";
import Faqs from "./components/Faqs.vue";
import ForgotPassword from "./components/ForgotPassword.vue";
import Login from "./components/Login.vue";
import TermsAndConditions from "./components/TermsAndConditions.vue";
import Tickets from "./components/Tickets.vue";
import Payment from "./components/Payment.vue";
import SetPassword from "./components/SetPassword.vue";
import Orders from "./components/Orders.vue";
import { hasCookie } from './utils';
import { LOGGED_IN_KEY } from './constants';

Vue.use(Router);
Vue.config.productionTip = false;
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

const router = new Router({
  routes: [
    {
      path: "/",
      component: Login,
      beforeEnter: (to, from, next) => {
        return hasCookie(LOGGED_IN_KEY) ? next("/events") : next();
      }
    },
    { path: "/login", component: Login },
    {
      path: "/downloadTickets",
      name: "downloadTickets",
      component: DownloadTickets,
      props: true,
    },
    { path: "/events", component: Events },
    { path: "/faqs", component: Faqs },
    { path: "/forgotPassword", component: ForgotPassword },
    { path: "/changePassword", component: SetPassword },
    { path: "/payment", component: Payment },
    { path: "/resetPassword/:token", component: SetPassword },
    { path: "/termsAndConditions", component: TermsAndConditions },
    { path: "/tickets/:performanceId", component: Tickets },
    { path: "/orders", component: Orders },
  ],
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
