<script>
// eslint-disable-next-line
import axios from "axios";
import SkinnyLayout from "./SkinnyLayout.vue";
import { BASE_API_URL, LOGGED_IN_KEY } from "../constants";
import { setCookie } from '../utils';

export default {
  name: "Login",
  components: {
    SkinnyLayout,
  },
  data() {
    return {
      email: "",
      password: "",
      error: false,
      busy: false,
      contactAccountCode: "",
      initialLoad: true,
    };
  },
  methods: {
    login() {
      this.initialLoad = false;
      if (this.email === "" || this.password === "") return;

      this.error = false;
      this.busy = true;
      axios
        .post(
          BASE_API_URL + "/Login",
          {
            email: this.email,
            password: this.password,
            contactAccountCode: this.$route.query.contactAccountCode,
            contactEmail: this.$route.query.contactEmail,
          },
          { withCredentials: true }
        )
        .then(() => {
          setCookie(LOGGED_IN_KEY, true);
          this.$router.push({ path: "/Events" });
        })
        .catch(() => {
          this.error = true;
          this.password = "";
          this.focusArea("#password");
          this.busy = false;
        });
    },
    nextField() {
      if (this.email === "") this.focusArea("#email");
      else this.focusArea("#password");
    },
    focusArea(area) {
      document.querySelector(area).focus();
    },
  },
  computed: {
    preventLogin() {
      if (this.initialLoad) return false;

      return this.busy || this.email === "" || this.password === "";
    },
  },
};
</script>

<template>
  <div>
    <div class="loader-parent-overlay" v-if="busy">
      <div class="p-75 loading-spinner"></div>
    </div>
    <skinny-layout>
      <form @submit.prevent="login">
        <div v-if="error" class="message-box error-message">
          <span
            >Either the e-mail address or password were incorrect. Please try
            again.
          </span>
        </div>

        <div>
          <label for="email">Email Address</label>
          <input
            v-model="email"
            id="email"
            type="text"
            @keyup.enter="nextField"
          />
        </div>

        <div>
          <label for="password">Password</label>
          <input
            v-model="password"
            id="password"
            type="password"
            @keyup.enter="nextField"
          />
        </div>

        <div>
          <button :disabled="preventLogin">Login</button>
        </div>
      </form>

      <footer>
        <router-link to="/forgotPassword" class="block m-t-15"
          >Forgot Password?</router-link
        >
      </footer>
    </skinny-layout>
  </div>
</template>
