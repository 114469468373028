export const BOX_UNAVAILABLE_MESSAGE =
  "Please note there are no VIP boxes available for this event. For alternative options please contact the hospitality team at ";
export const HOSPITALITY_EMAIL =
  "mailto:hospitality@accliverpool.com";
export const HOSPITALITY_DISPLAY_EMAIL =
  "hospitality@accliverpool.com";
export const TICKETS_FULLY_BOOKED_MESSAGE =
  "This event has no tickets left to book. If you have any further queries please contact the hospitality team at ";
export const OFF_SALE_DATE_MESSAGE =
  "Please confirm your order before ";
export const TICKETS_LIMIT = 12;
export const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
export const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export const OPTING_OUT_CONFIRMATION_MESSAGE = "I confirm that I am agreeing to release my box to this event for reselling by Arena Club hospitality team.";
export const BOX_OPTED_OUT_MESSAGE = "You have agreed to resell your VIP Box for this event. If you have any further queries please contact the hospitality team at ";
export const BOX_STATUS_OPTED_OUT = 1;
export const BOX_STATUS_OPTABLE = 3;
export const LOGGED_IN_KEY = "LOGGED_IN";
export const HPP_URL = process.env.VUE_APP_HPP_URL;
export const CALLBACK_URL = process.env.VUE_APP_CALLBACK_URL;
export const PAYMENT_PAGE_REDIRECT_ERROR_MESSAGE = "An error occurred when redirecting you to the payment page. \nPlease try again, and if the problem persists contact " + HOSPITALITY_DISPLAY_EMAIL;
export const ORDER_SUCCESS_TITLE = "Order Successful";
export const ORDER_FAIL_TITLE = "Order Unsuccessful";
export const ORDER_SUCCESS_MESSAGE = "Thank you for your order. Your payment has been processed successfully and your booking has been confirmed.";
export const GENERAL_ORDER_FAIL_MESSAGE = "Something has gone wrong during the payment or during order. Please try again or contact " + HOSPITALITY_DISPLAY_EMAIL;
export const ORDER_FAIL_CREATION_MESSAGE = "Something has gone wrong during order creation. Please contact " + HOSPITALITY_DISPLAY_EMAIL;