<script>
import axios from "axios";
import { BASE_API_URL } from "../constants";

export default {
  name: "Faqs",
  data() {
    return {
      faqs: [],
      loading: true,
      error: false,
    };
  },
  methods: {
    getFaqs() {
      this.error = false;
      axios
        .get(BASE_API_URL + "/Faqs", { withCredentials: true })
        .then(({ data }) => {
          this.faqs = data.faqs;
          this.loading = false;
        })
        .catch(() => {
          this.$router.push({ path: "/" });
        });
    },
  },
  computed: {},
  mounted() {
    this.getFaqs();
  },
};
</script>

<template>
  <div class="slim-page">
    <div
      class="pink-border p-75 margin-top"
      v-for="(faq, index) in faqs"
      :key="index"
    >
      <h2>Q. {{ faq.question }}</h2>
      <div>A. <span v-html="faq.answer"/></div>
    </div>
  </div>
</template>
