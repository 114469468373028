<script>
import axios from "axios";
import ValidIndicator from "./ValidIndicator.vue";
import SkinnyLayout from "./SkinnyLayout.vue";
import { BASE_API_URL } from "../constants";
import { ensureLoggedInOrRedirect } from "../utils";

export default {
  name: "SetPassword",
  components: {
    ValidIndicator,
    SkinnyLayout,
  },
  data() {
    return {
      busy: false,
      confirmPassword: "",
      error: false,
      oldPassword: "",
      password: "",
      success: false,
    };
  },
  methods: {
    validateToken() {
      this.busy = true;
      axios
        .get(BASE_API_URL + "/ValidateToken/" + this.token)
        .then(({ data }) => {
          if (data.success) {
            this.busy = false;
          } else {
            this.error = true;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    setPassword() {
      this.error = false;
      this.busy = true;
      if (this.isResetPassword) {
        axios
          .post(BASE_API_URL + "/ResetPassword", {
            token: this.token,
            password: this.password,
          })
          .then(({ data }) => {
            if (data.success) {
              this.success = true;
            } else {
              this.error = true;
            }
          })
          .catch(() => {
            this.error = true;
          });
      } else {
        axios
          .post(
            BASE_API_URL + "/ChangePassword",
            {
              newPassword: this.password,
              oldPassword: this.oldPassword,
            },
            { withCredentials: true }
          )
          .then(({ data }) => {
            this.success = data;
            this.error = !data;
          })
          .catch(() => {
            this.error = true;
          });
      }
    },
  },
  computed: {
    preventReset() {
      return (
        this.busy ||
        !this.passwordsMatch ||
        !this.passwordComplexitySufficient ||
        this.oldPasswordIsEmpty
      );
    },
    oldPasswordIsEmpty() {
      return this.oldPassword === "" && !this.token;
    },
    passwordsMatch() {
      return this.password === this.confirmPassword;
    },
    passwordContainsCapital() {
      return /[A-Z]/.test(this.password);
    },
    passwordContainsNumber() {
      return /[0-9]/.test(this.password);
    },
    passwordContainsSymbol() {
      return /[^a-z0-9]+/i.test(this.password);
    },
    passwordEightOrMoreCharacters() {
      return this.password.length >= 8;
    },
    passwordComplexitySufficient() {
      return (
        this.passwordContainsCapital &&
        this.passwordContainsNumber &&
        this.passwordContainsSymbol &&
        this.passwordEightOrMoreCharacters
      );
    },
    token() {
      return this.$route.params.token;
    },
    isChangePassword() {
      return !this.token;
    },
    isResetPassword() {
      return this.token;
    },
  },
  mounted() {
    if (this.isResetPassword) this.validateToken();
    if (this.isChangePassword && ensureLoggedInOrRedirect(this.$router))
        return;
  },
};
</script>

<template>
  <skinny-layout>
    <form @submit.prevent="setPassword">
      <div v-if="error" class="message-box error-message">
        <span v-if="isResetPassword"
          >The token was invalid/expired, or there was a problem setting your
          new password- please reload the page and try again.
        </span>
        <span v-if="isChangePassword"
          >There was a problem setting your new password. Please reload the page
          and try again.
        </span>
      </div>

      <div v-if="success" class="message-box success-message">
        <span v-if="isResetPassword">
          Your password was reset. Please
          <router-link to="/">
            <a class="block" href="#">login.</a>
          </router-link>
        </span>
        <span v-if="isChangePassword">
          Your password was changed successfully.
        </span>
      </div>
      <div v-if="isChangePassword">
        <label for="old-password">Old Password</label>
        <input v-model="oldPassword" id="old-password" type="password" />
      </div>
      <div>
        <label for="password">Password</label>
        <input v-model="password" id="password" type="password" />
      </div>

      <div>
        <label for="confirm-password">Confirm Password</label>
        <input
          v-model="confirmPassword"
          id="confirm-password"
          type="password"
        />
      </div>

      <div>
        <label class="text-underline">Password must:</label>
        <div>
          <div>
            <valid-indicator
              :valid="passwordEightOrMoreCharacters"
            ></valid-indicator>
            Minimum 8 letters
          </div>
          <div>
            <valid-indicator :valid="passwordContainsCapital"></valid-indicator>
            One capital (UPPERCASE)
          </div>
          <div>
            <valid-indicator :valid="passwordContainsNumber"></valid-indicator>
            One number
          </div>
          <div>
            <valid-indicator :valid="passwordContainsSymbol"></valid-indicator>
            One symbol
          </div>
          <div>
            <valid-indicator :valid="passwordsMatch"></valid-indicator>
            Match
          </div>
        </div>
      </div>
      <br />

      <div>
        <button :disabled="preventReset">Submit</button>
      </div>
    </form>
  </skinny-layout>
</template>
