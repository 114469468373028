<script>
import axios from "axios";
import { BASE_API_URL } from "../constants";

export default {
  name: "DownloadTickets",
  props: {
    order: Object,
  },
  data() {
    return {
      barcodes: [],
      busy: true,
    };
  },
  methods: {
    getTicketBarcodes() {
      axios
        .get(
          BASE_API_URL +
            "/DownloadTickets/" +
            this.$route.params.order.orderNumber,
          { withCredentials: true }
        )
        .then(({ data }) => {
          this.barcodes = data.tickets;
          this.busy = false;
        })
        .catch(() => {
          // TODO: Display an error message
          console.log("Failed to getTicketBarcodes");
          this.$router.push({ path: "/Orders" });
        });
    },
    downloadAllTicket(barcodes) {
      for (let i = 0; i < barcodes.length; i++) {
        this.downloadTicket(
          barcodes[i],
          this.$route.params.order.items[i].ticketNumber
        );
      }
    },
    downloadTicket(barcode, barcodeTicketNumber) {
      const link = document.createElement("a");
      link.href = "data:image/png;base64," + barcode;
      link.download = "#" + barcodeTicketNumber + ".png";
      link.click();
    },
  },
  mounted() {
    if (this.$route.params.order) {
      this.getTicketBarcodes();
    } else {
      this.$router.push({ path: "/Orders" });
    }
  },
};
</script>
<template>
  <div>
    <div class="loader-parent-overlay" v-if="busy">
      <div class="p-75 loading-spinner"></div>
    </div>
    <div v-else>
      <div class="flex-grid flex-grid-columns">
        <div v-for="(barcode, i) in this.barcodes" :key="i">
          <div class="pink-border p-75">
            <label># {{ $route.params.order.items[i].ticketNumber }}</label>
            <img
              class="event-thumbnail"
              :src="`data:image/png;base64,${barcode}`"
              alt="ticket barcode"
            />
            <button
              class="margin-top"
              @click="
                downloadTicket(
                  barcode,
                  $route.params.order.items[i].ticketNumber
                )
              "
            >
              Download Ticket
            </button>
          </div>
        </div>
      </div>
      <div>
        <button class="margin-top" @click="downloadAllTicket(barcodes)">
          Download All Tickets
        </button>
      </div>
    </div>
  </div>
</template>
